"use strict";
document.addEventListener("DOMContentLoaded", function () {
    const btnColor = document.querySelector(".btn-color");
    const btnText = document.querySelector(".btn-text");
    // 初期設定の読み込み
    restoreSettings();
    btnColor.addEventListener("click", function () {
        toggleDarkMode();
    });
    btnText.addEventListener("click", function () {
        toggleTextSize();
    });
    function toggleDarkMode() {
        document.body.classList.toggle("dark-mode");
        btnColor.classList.toggle("active");
        saveSettings();
    }
    function toggleTextSize() {
        const html = document.documentElement;
        const currentFontSize = html.style.fontSize || "100%";
        if (currentFontSize === "100%") {
            html.style.fontSize = "130%";
            document.body.classList.add("text-size-large");
        }
        else {
            html.style.fontSize = "100%";
            document.body.classList.remove("text-size-large");
        }
        btnText.classList.toggle("active");
        saveSettings();
    }
    function saveSettings() {
        const darkMode = document.body.classList.contains("dark-mode") ? "true" : "false";
        const textSizeLarge = document.body.classList.contains("text-size-large") ? "true" : "false";
        localStorage.setItem("settings", JSON.stringify({ darkMode, textSizeLarge }));
    }
    function restoreSettings() {
        const settingsString = localStorage.getItem("settings");
        if (settingsString) {
            const { darkMode, textSizeLarge } = JSON.parse(settingsString);
            if (darkMode === "true") {
                toggleDarkMode();
            }
            if (textSizeLarge === "true") {
                toggleTextSize();
            }
        }
    }
});
