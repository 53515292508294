import Swiper from 'swiper';
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
// import Swiper styles
import 'swiper/swiper-bundle.css';
// Initialize Swiper for .front-slider
const frontSwiper = new Swiper('.front-main .swiper', {
    loop: true,
    effect: "fade",
    grabCursor: true,
    modules: [Autoplay, EffectFade],
    autoplay: {
        delay: 4000, // 4秒後に次のスライドへ
        disableOnInteraction: false, // ユーザーが操作しても自動再生を継続
    },
    speed: 2000, // 2秒かけてフェード
});
// Initialize Swiper for .popular-slider
const accessSwiper = new Swiper('.access-slider .swiper', {
    slidesPerView: 'auto',
    spaceBetween: 24,
    grabCursor: true,
    modules: [Navigation, Pagination],
    navigation: {
        nextEl: '.access-next',
        prevEl: '.access-prev'
    },
    breakpoints: {
        1025: {
            spaceBetween: 34
        }
    },
    injectStyles: ['overflow: visible']
});
// Initialize Swiper for .pickup-slider
const pickupSwiper = new Swiper('.pickup-slider .swiper', {
    slidesPerView: 'auto',
    spaceBetween: 24,
    grabCursor: true,
    modules: [Navigation, Pagination],
    navigation: {
        nextEl: '.pickup-next',
        prevEl: '.pickup-prev'
    },
    breakpoints: {
        1025: {
            spaceBetween: 34
        }
    },
    injectStyles: ['overflow: visible']
});
// Initialize Swiper for .pickup-slider
const voiceSlider = new Swiper('.voice-slider .swiper', {
    slidesPerView: 1,
    loop: true,
    grabCursor: true,
    modules: [Navigation, Pagination],
    navigation: {
        nextEl: '.voice-next',
        prevEl: '.voice-prev'
    },
    breakpoints: {
        576: {
            slidesPerView: 2,
            spaceBetween: 50,
        },
        1025: {
            slidesPerView: 3,
            spaceBetween: 50,
        },
        1400: {
            slidesPerView: 4,
            spaceBetween: 100,
        }
    },
    injectStyles: ['overflow: visible']
});
export { frontSwiper, accessSwiper, pickupSwiper, voiceSlider };
