"use strict";
window.onload = () => {
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            const href = this.getAttribute('href');
            if (href && href.startsWith('#')) {
                const targetId = href.substring(1);
                let target = document.getElementById(targetId);
                // タブ内にターゲットが見つからない場合、ページ全体から検索
                if (!target) {
                    target = document.querySelector(href);
                }
                if (target) {
                    let headerHeight = 0;
                    const header = document.querySelector('.site-header');
                    if (header) {
                        headerHeight = header.offsetHeight;
                    }
                    const targetRect = target.getBoundingClientRect();
                    const position = window.scrollY + targetRect.top - headerHeight;
                    // スクロール位置をコンソールログに出力
                    console.log('スクロール位置:', position);
                    window.scroll({
                        top: position,
                        behavior: 'smooth'
                    });
                }
            }
        });
    });
};
// スクロール機能のクラスを定義する
class ScrollFunction {
    // 初期化メソッド
    init() {
        document.addEventListener('DOMContentLoaded', () => {
            // ページ読み込み時の処理をここに記述する
        });
    }
    // スクロールイベントハンドラ
    scrollHandler() {
        // スクロールが発生したときの処理をここに記述する
        document.querySelectorAll('a[href^="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault(); // デフォルトのスクロール動作を無効化
                // アンカーリンクの href から対象の要素を取得
                const href = this.getAttribute('href');
                if (href && href.startsWith('#')) {
                    const target = document.querySelector(href);
                    if (target) {
                        // 対象の要素までスクロール
                        const header = document.querySelector('.site-header');
                        const headerHeight = header.offsetHeight;
                        const position = target.offsetTop - headerHeight;
                        window.scroll({
                            top: position,
                            behavior: 'smooth' // スムーズにスクロール
                        });
                    }
                }
            });
        });
    }
}
// スクロール機能のインスタンスを作成して初期化する
const myScrollFunction = new ScrollFunction();
myScrollFunction.init();
// スクロールイベントハンドラを登録する
window.addEventListener('scroll', myScrollFunction.scrollHandler);
window.addEventListener("DOMContentLoaded", () => {
    // ページのトップにスクロールする関数
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth" // スムーズなスクロールを実現するためのオプション
        });
    };
    // リンク要素を取得する
    const scrollToTopLink = document.getElementById("scrollToTop");
    // リンクが存在し、クリックされた時のイベントを追加する
    if (scrollToTopLink) {
        scrollToTopLink.addEventListener("click", (event) => {
            event.preventDefault(); // デフォルトのリンクの動作を無効化
            scrollToTop(); // ページのトップにスクロール
        });
    }
});
